import React from "react";
import pageBanner from "./assets/page_banner.png";

import { MapIcon, CheckIcon } from "@heroicons/react/outline";

export default function Banner({ title }) {
  return (
    <>
      <div>
        <div className=" relative">
          <img
            className="brightness-50 object-cover w-full md:h-[200px]"
            src={pageBanner}
            alt="Logo"
          />
          <div className="uppercase absolute text-5xl text-white font-bold top-[40%] md:top-[45%] md:text-3xl left-0 right-0 m-auto font-montserrat text-center">
            {title}
          </div>
        </div>
      </div>
    </>
  );
}
