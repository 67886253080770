import React from "react";
import { useState } from "react";
import Navigation from "./Navigation";
import Banner from "./Banner";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import aa from "./assets/glasses/Agio-80_1-0.jpeg";
import ab from "./assets/glasses/Agio-80_1-1.jpeg";
import ac from "./assets/glasses/Agio-80_1-2.jpeg";
import ad from "./assets/glasses/Agio-80_1-3.jpeg";
import ae from "./assets/glasses/Agio-80_1-4.jpeg";
import af from "./assets/glasses/Agio-80_1-5.jpeg";
import ag from "./assets/glasses/Agio-80_1-6.jpeg";
import ah from "./assets/glasses/Agio-80_1-7.jpeg";
import ai from "./assets/glasses/Agio-80_1-8.jpeg";
import aj from "./assets/glasses/Agio-80_1-9.jpeg";
import ak from "./assets/glasses/Agio-80_1-10.jpeg";
import al from "./assets/glasses/Agio-80_1-11.jpeg";
import am from "./assets/glasses/Agio-80_1-12.jpeg";
import an from "./assets/glasses/Agio-80_1-13.jpeg";
import ao from "./assets/glasses/Agio-80_1-14.jpeg";
import ap from "./assets/glasses/Agio-80_1-15.jpeg";
import aq from "./assets/glasses/Agio-80_1-16.jpeg";
import ar from "./assets/glasses/Agio-80_1-17.jpeg";
import as from "./assets/glasses/Agio-80_1-18.jpeg";
import at from "./assets/glasses/Agio-80_1-20.jpeg";

const images = [
  {
    src: aa,
    original: aa,
    width: 568,
    height: 320,
  },
  {
    src: ab,
    original: ab,
    width: 568,
    height: 320,
  },
  {
    src: ac,
    original: ac,
    width: 568,
    height: 320,
  },
  {
    src: ad,
    original: ad,
    width: 568,
    height: 320,
  },
  {
    src: ae,
    original: ae,
    width: 568,
    height: 320,
  },
  {
    src: af,
    original: af,
    width: 568,
    height: 320,
  },
  {
    src: ag,
    original: ag,
    width: 568,
    height: 320,
  },
  {
    src: ah,
    original: ah,
    width: 568,
    height: 320,
  },
  {
    src: ai,
    original: ai,
    width: 568,
    height: 320,
  },
  {
    src: aj,
    original: aj,
    width: 568,
    height: 320,
  },
  {
    src: ak,
    original: ak,
    width: 568,
    height: 320,
  },
  {
    src: al,
    original: al,
    width: 568,
    height: 320,
  },
  {
    src: am,
    original: am,
    width: 568,
    height: 320,
  },
  {
    src: an,
    original: an,
    width: 568,
    height: 320,
  },
  {
    src: ao,
    original: ao,
    width: 568,
    height: 320,
  },
  {
    src: ap,
    original: ap,
    width: 568,
    height: 320,
  },
  {
    src: aq,
    original: aq,
    width: 568,
    height: 320,
  },
  {
    src: ar,
    original: ar,
    width: 568,
    height: 320,
  },
  {
    src: as,
    original: as,
    width: 568,
    height: 320,
  },
  {
    src: at,
    original: at,
    width: 568,
    height: 320,
  },
];

export default function Glasses() {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <>
      <Navigation />
      <Banner title="Glasses and Sunglasses" />

      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </>
  );
}
