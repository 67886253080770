import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import AboutUs from "./AboutUs";
import Glasses from "./Glasses";
import Conditions from "./Conditions";
import ContactUs from "./ContactUs";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/glasses" element={<Glasses />} />
      <Route path="/conditions" element={<Conditions />} />
      <Route path="/contactus" element={<ContactUs />} />
    </Routes>
  );
}

export default App;
