import React from "react";
import birenFull from "./assets/biren_full.png";

import Navigation from "./Navigation";
import Banner from "./Banner";

import { MapIcon, CheckIcon } from "@heroicons/react/outline";

const EYE_SERVICES = [
  "Annual Eye Exam",
  "Custom Prescription Glasses",
  "Custom Sunglasses",
  "Low/Blurry Vision",
  "Cataracts",
  "Glaucoma",
  "Ocular Disease",
  "Chronic Dry Eyes",
  "Eye Infection",
  "Diabetic Retinopathy",
  "Amblyopia (lazy eye)",
  "Hypertensive Retinopathy",
];

const INSURANCES = [
  "Medicare",
  "Blue Cross and Blue Shield (BCBS)",
  "Humana",
  "Cigna",
  "United Healthcare",
  "Aetna",
];

export default function AboutUs() {
  return (
    <>
      <Navigation />

      <Banner title="About Us" />

      <div className="flex flex-col bg-main-gray-100 items-center pt-8 font-montserrat">
        <div className="text-5xl font-bold text-main-gray-600">
          Dr. Biren Patel O.D.
        </div>

        <div className="flex p-8 justify-center items-center gap-4 w-full *:font-montserrat bg-main-gray-100">
          <div className="w-[50%]">
            <img src={birenFull} alt="Logo" />
          </div>
          <div className="w-[50%] flex justify-center items-center">
            <div className="w-[500px]">
              <p>
                Dr. Biren Patel is a board certified optometrist who obtained
                his graduate degree at the New England College of Optometry in
                Boston, MA. He completed his externships at various clinics
                including The Eye Centers of South Florida, Manchester VA
                Medical Center, and numerous health centers throughout the
                Boston area.
              </p>

              <p>
                He is highly trained across various specialties and diseases,
                encompassing primary care, cataracts, glaucoma, diabetic
                retinopathy, chronic dry eyes, amblyopia (lazy eye), low vision,
                and ocular diseases. His expertise spans a broad spectrum of
                medical conditions within the field.
              </p>

              <p>
                Dr. Patel is enthusiastic about offering advanced mobile eye
                care services to skilled nursing facilities and assisted and
                independent living communities across Illinois. Central to his
                practice is a commitment to patient-centered care, delivering
                personalized and compassionate treatment in a convenient
                setting. His passion and dedication drive him to innovate the
                healthcare industry by leveraging real-time data, trends, and
                technology to provide proactive care and enhance patient
                outcomes.
              </p>

              <p>
                During his free time, Dr. Patel enjoys giving back to the
                community by educating and guiding all groups of patients. He
                had the opportunity to provide eye exams to the underserved
                communities in the Dominican Republic. Beyond his professional
                endeavors, Dr. Patel cherishes traveling, exploring the
                outdoors, and sharing moments with loved ones.
              </p>

              <p>*Dr. Patel is fluent in English, Spanish and Gujarati.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
