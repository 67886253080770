import React from "react";
import dr from "./assets/dr.png";
import macd from "./assets/macd.png";
import glaucoma from "./assets/glaucoma.png";
import Navigation from "./Navigation";
import Banner from "./Banner";

import { MapIcon, CheckIcon } from "@heroicons/react/outline";

export default function Conditions() {
  return (
    <>
      <Navigation />
      <Banner title="Ocular Conditions" />

      <div className="flex justify-center font-montserrat mt-8 mb-8">
        <div className="flex flex-col justify-center items-center *:uppercase mb-8 text-center">
          <div className="text-md font-bold text-main-blue-600 tracking-wider mb-2">
            Treatments
          </div>
          <div className="text-4xl font-light text-main-gray-600 md:text-2xl">
            We specialize in
          </div>
          <div className="text-5xl font-black text-main-gray-600 md:text-3xl">
            various procedures.
          </div>
        </div>
      </div>

      <div className="flex xl:flex-col xl:gap-y-8 justify-center font-poppins text-main-gray-600 mb-16">
        <div className="flex flex-col items-center md:p-0 mdp:pl-4">
          <img
            className="h-[300px] pb-4"
            src={macd}
            alt="Diabetic retinopathy"
          />
          <div className="text-2xl font-bold pb-4 text-center">
            Age-related macular degeneration
          </div>
          <div>
            <p className="w-[400px] md:w-[300px]">
              Dry macular degeneration progresses slowly as drusen accumulate in
              the retina, leading to damage to photoreceptors over time.
              <br /> <br /> This condition may cause geographic atrophy,
              resulting in blind spots or scotomas in central vision. <br />{" "}
              <br /> Regular monitoring and timely reporting of vision changes
              are essential, as dry macular degeneration can develop into wet
              macular degeneration, which is treatable if caught early.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center md:p-0 mdp:pl-4">
          <img
            className="h-[300px] pb-4"
            src={glaucoma}
            alt="Diabetic retinopathy"
          />
          <div className="text-2xl font-bold pb-4 text-center">Glaucoma</div>
          <div>
            <p className="w-[400px] md:w-[300px]">
              Glaucoma is a progressive eye condition that damages the optic
              nerve, often due to high eye pressure, leading to vision loss and
              potentially blindness if untreated. <br /> <br /> It affects about
              2% of the population, with most people unaware they have it until
              significant damage has occurred. <br /> <br /> While glaucoma
              cannot be cured or reversed, lowering eye pressure through drops,
              laser, or surgery can slow further damage, making early detection
              crucial for preserving vision.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center md:p-0 mdp:pl-4 mdp:pr-4">
          <img className="h-[300px] pb-4" src={dr} alt="Diabetic retinopathy" />
          <div className="text-2xl font-bold pb-4 text-center">
            Diabetic Retinopathy
          </div>
          <div>
            <p className="w-[400px] md:w-[300px]">
              Diabetic retinopathy is a complication of diabetes that damages
              the blood vessels in the retina, potentially leading to vision
              loss and blindness.
              <br /> <br /> Regular comprehensive dilated eye exams are crucial
              for early detection, as the condition may not show symptoms
              initially. <br /> <br /> Proper diabetes management, including
              healthy habits and medication, can help prevent or delay the
              progression of vision loss.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
