import React from "react";
import formBanner from "./assets/form_banner.png";
import ContactForm from "./ContactForm";
import Navigation from "./Navigation";

export default function ContactUs() {
  return (
    <>
      <Navigation />

      <div className="mb-4 relative">
        <div>
          <img
            className="w-full object-cover md:h-[200px]"
            src={formBanner}
            alt="Banner"
          />
        </div>
        <div className="flex flex-col justify-center items-center py-8 gap-4 w-full *:font-montserrat absolute top-0">
          <div className="flex flex-col justify-center items-center *:uppercase mb-4">
            <div className="text-md font-bold text-main-blue-100 tracking-wider mb-2">
              Interest Form
            </div>
            <div className="text-4xl font-light text-white md:text-2xl">
              Does your facility need an
            </div>
            <div className="text-5xl font-black text-white md:text-2xl">
              In-house optometrist?
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full bg-main-gray-100 h-[500px]"></div>

        <div className="flex flex-col w-[1000px] lg:w-full justify-center items-center py-8 gap-4 *:font-poppins absolute bg-white top-[200px] m-auto right-0 left-0 text-center">
          <ContactForm />
        </div>
      </div>
    </>
  );
}
