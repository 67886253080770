import React from "react";
import { useState } from "react";
import form from "./assets/form.png";
import axios from "axios";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = () => {
    if (!name || !email || !phone || !message) {
      setError("Please fill out all fields.");
      return;
    }

    if (email.indexOf("@") === -1) {
      setError("Please enter a valid email address.");
      return;
    }

    const emailMessage = `<div>Name: ${name}</div><div>Email: ${email}</div><div>Phone: ${phone}</div><div>Message: ${message}</div>`;

    axios
      .post(
        "https://api.brevo.com/v3/smtp/email",
        {
          sender: {
            name: "Enroute Eye Care",
            email: "yang.k@me.com",
          },
          to: [
            {
              email: "vision@enrouteeyecare.com",
              name: "Enroute Eye Care",
            },
          ],
          subject: "Enroute Eyecare Interest Form Received",
          htmlContent: emailMessage,
        },
        {
          headers: {
            "api-key":
              "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",
          },
        }
      )
      .then((response) => {
        setSuccess("Form submitted successfully!");
        setError("");
      })
      .catch((error) => {
        setError("An error occurred. Please try again.");
        setSuccess("");
      });
  };

  return (
    <>
      <div className="flex">
        <div className="w-[50%] mb-4 pl-8 lg:hidden">
          <img className="" src={form} alt="Banner" />
        </div>
        <div className="w-[50%] lg:w-full pr-8 md:pr-0 flex flex-col justify-center items-center mb-4">
          <div className="text-xl mb-8 text-main-gray-600">
            Please fill out this interest form and we'll get back to you as soon
            as possible.
          </div>

          <div className="text-xl text-main-gray-600">Or, reach us at:</div>

          <div className="text-xl text-main-gray-600">
            Phone: <b>(630) 998-0948</b>
          </div>

          <div className="text-xl text-main-gray-600 mb-8">
            Fax: <b>(312) 878-5608</b>
          </div>

          <div className="flex flex-col gap-4">
            <input
              type="text"
              placeholder="Name"
              className="w-96 md:w-80 p-2 border border-main-gray-600"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              className="w-96 md:w-80 p-2 border border-main-gray-600"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Phone"
              className="w-96 md:w-80 p-2 border border-main-gray-600"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <textarea
              placeholder="Message"
              className="w-96 md:w-80 h-48 p-2 border border-main-gray-600"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <button
              onClick={handleSubmit}
              className="bg-main-blue-100 text-white p-2 w-full font-montserrat uppercase font-bold"
            >
              Submit
            </button>

            {error && <div className="text-red-500">{error}</div>}
            {success && <div className="text-green-500">{success}</div>}
          </div>
        </div>
      </div>
    </>
  );
}
