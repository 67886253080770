import React from "react";
import logo from "./assets/logo.jpg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { PhoneIcon, MailIcon } from "@heroicons/react/outline";

export default function Navigation() {
  return (
    <>
      <div className="bg-main-blue-600 h-12 w-full px-16 sm:px-1 *:font-poppins *:tracking-wider">
        <div className="flex justify-center items-center h-full justify-between">
          <div className="flex gap-2 items-center sm:text-xs">
            <PhoneIcon className="h-5 w-5 text-white" />
            <span>
              <a className="text-white" href="tel:+16309980948">
                (630) 998-0948
              </a>
            </span>
          </div>
          <div className="flex gap-2 items-center  sm:text-xs">
            <MailIcon className="h-5 w-5 text-white" />
            <span className="text-white">
              <a className="text-white" href="mailto:vision@enrouteeyecare.com">
                vision@enrouteeyecare.com
              </a>
            </span>
          </div>
        </div>
      </div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <div className="logo">
              <a href="/">
                <img className="w-48 xl:w-36" src={logo} alt="Logo" />
              </a>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex font-montserrat font-bold ml-4 gap-x-4 [&_a]:text-main-blue-600 [&_a]:uppercase [&_a]:tracking-wider [&_a]:cursor-pointer">
              <Nav.Link className="hover:text-main-blue-100" href="/aboutus">
                About Us
              </Nav.Link>
              <Nav.Link className="hover:text-main-blue-100" href="/glasses">
                Glasses & Sunglasses
              </Nav.Link>
              <Nav.Link className="hover:text-main-blue-100" href="/conditions">
                Ocular Conditions
              </Nav.Link>
              <Nav.Link className="hover:text-main-blue-100" href="/contactus">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
